import { lazy, ReactNode, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SWRConfig } from "swr";
import {
  SnackbarProvider,
  SnackbarProviderProps,
  closeSnackbar,
} from "notistack";
import CloseButton from "react-bootstrap/CloseButton";

import { SearchContextProvider } from "./pages/Search/useSearch";
import { Loading } from "components/Loading/Loading";
import { RecordContextProvider } from "pages/Record/RecordContext";
import { UserContextProvider } from "hooks/useUser";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import UnprotectedRouteContainer from "components/Containers/UnprotectedRouteContainer";

import "./App.scss";

// Setup amCharts4
import * as am4core from "@amcharts/amcharts4/core";
// eslint-disable-next-line
import * as am4charts from "@amcharts/amcharts4/charts";
// eslint-disable-next-line
import * as am4maps from "@amcharts/amcharts4/maps";
import {
  NotificationsContextProvider,
  ExportNotificationsContextProvider,
} from "context/NotificationsContext";
am4core.addLicense("CH207064308");
am4core.addLicense("MP207064308");
am4core.addLicense("TL207064308");
// Disabling the animation for performance reasons
// am4core.useTheme(am4themes_animated);

const Landing = lazy(() => import("./pages/Landing/Landing"));
const Login = lazy(() => import("./pages/Login/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const RecordSearchResults = lazy(
  () => import("./pages/Search/RecordSearchResults/RecordSearchResults")
);
const StrategicObjectiveResults = lazy(
  () =>
    import(
      "pages/Search/SearchResults/StrategicObjectiveResults/StrategicObjectiveResults"
    )
);
const Record = lazy(() => import("./pages/Record/Record"));
const MyTags = lazy(() => import("./pages/MyTags/MyTags"));
const MyExports = lazy(() => import("./pages/MyExports/MyExports"));
const MyBookmarks = lazy(() => import("./pages/MyBookmarks/MyBookmarks"));
const UserManagement = lazy(
  () => import("./pages/UserManagement/UserManagement")
);
const UserDetails = lazy(
  () => import("./pages/UserManagement/UserDetails/UserDetails")
);

export default function App() {
  return (
    <div className="app">
      <Router>
        <ProviderStack>
          <Content />
        </ProviderStack>
      </Router>
    </div>
  );
}

function Content() {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={Landing} />
      <ProtectedRoute
        exact
        path={["/search"]}
        component={RecordSearchResults}
      />
      <ProtectedRoute
        exact
        path={"/record/:tablename/:rowid"}
        component={Record}
      />
      <ProtectedRoute
        exact
        path={[
          "/strategic-objective/:id",
          "/strategic-objective/:id/:tablename/:rowid",
        ]}
        component={StrategicObjectiveResults}
      />
      <ProtectedRoute
        exact
        path={["/my-tags", "/my-tags/:tagid", "/my-tags/:tablename/:rowid"]}
        component={MyTags}
      />
      <ProtectedRoute exact path="/my-exports" component={MyExports} />
      <ProtectedRoute exact path={["/my-bookmarks"]} component={MyBookmarks} />
      <ProtectedRoute
        exact
        path="/user-management/user/:id"
        component={UserDetails}
        authorizedUsers={["POWER"]}
      />
      <ProtectedRoute
        path="/user-management"
        component={UserManagement}
        authorizedUsers={["POWER"]}
      />
      <UnprotectedRouteContainer>
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
      </UnprotectedRouteContainer>
    </Switch>
  );
}

const _SnackbarProviderProps: SnackbarProviderProps = {
  autoHideDuration: 5000,
  maxSnack: 10,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  action: (key) => (
    <CloseButton
      aria-label="dismiss"
      onClick={() => closeSnackbar(key)}
      variant="white"
    />
  ),
};

function ProviderStack({ children }: { readonly children: ReactNode }) {
  return (
    <Suspense fallback={<Loading />}>
      <SnackbarProvider {..._SnackbarProviderProps}>
        <SWRConfig>
          <UserContextProvider>
            <NotificationsContextProvider>
              <ExportNotificationsContextProvider>
                <SearchContextProvider>
                  <RecordContextProvider>{children}</RecordContextProvider>
                </SearchContextProvider>
              </ExportNotificationsContextProvider>
            </NotificationsContextProvider>
          </UserContextProvider>
        </SWRConfig>
      </SnackbarProvider>
    </Suspense>
  );
}
