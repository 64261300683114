import { Dropdown } from "react-bootstrap";

import "./Avatar.scss";

// helper from here -> https://stackoverflow.com/a/66239174/8414492
const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(" ");
  return allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
};

interface IAvatarProps {
  readonly fullName: string;
  readonly size?: "small" | "medium" | "large";
}

export default function Avatar({ fullName, size = "medium" }: IAvatarProps) {
  return (
    <Dropdown.Toggle id="avatar" className={size}>
      {getInitials(fullName)}
    </Dropdown.Toggle>
  );
}
