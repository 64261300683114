import { createContext, useState, useMemo } from "react";

import { components } from "types/OpenAPI";

type HierarchiesType = {
  allHierarchy?: components["schemas"]["RecordHierarchy"][];
  relevantHierarchy?: components["schemas"]["RecordHierarchy"][];
};

interface RecordContextProviderProps {
  readonly children: React.ReactNode;
}

interface RecordContextProps {
  recordDetails: components["schemas"]["RecordDetail"];
  setRecordDetails: React.Dispatch<
    React.SetStateAction<components["schemas"]["RecordDetail"]>
  >;
  recordHierarchy: HierarchiesType;
  setRecordHierarchy: React.Dispatch<React.SetStateAction<HierarchiesType>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRecordId: components["schemas"]["RecordSource"];
  setSelectedRecordId: React.Dispatch<
    React.SetStateAction<components["schemas"]["RecordSource"]>
  >;
}

export const RecordContext = createContext<RecordContextProps>({
  recordDetails: {},
  setRecordDetails: () => void 0,
  recordHierarchy: {},
  setRecordHierarchy: () => void 0,
  loading: false,
  setLoading: () => void 0,
  selectedRecordId: {},
  setSelectedRecordId: () => void 0,
});

export function RecordContextProvider({
  children,
}: RecordContextProviderProps) {
  const [recordDetails, setRecordDetails] =
    useState<components["schemas"]["RecordDetail"]>();
  const [recordHierarchy, setRecordHierarchy] = useState<HierarchiesType>({});
  const [loading, setLoading] = useState(false);
  const [selectedRecordId, setSelectedRecordId] =
    useState<components["schemas"]["RecordSource"]>();

  const contextValue = useMemo(
    () => ({
      recordDetails,
      setRecordDetails,
      loading,
      setLoading,
      selectedRecordId,
      setSelectedRecordId,
      recordHierarchy,
      setRecordHierarchy,
    }),
    [recordDetails, recordHierarchy, loading, selectedRecordId]
  );

  return (
    <RecordContext.Provider value={contextValue}>
      {children}
    </RecordContext.Provider>
  );
}
