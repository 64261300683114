import { SearchFilter } from ".";

export const REQUEST_DATA = "REQUEST_DATA";
export const UPDATE_RESULTS = "UPDATE_RESULTS";
export const UPDATE_SEARCH_TYPE = "UPDATE_SEARCH_TYPE";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const CLEAR_SEARCH_TERM = "CLEAR_SEARCH_TERM";
export const RESET_STATE = "RESET_STATE";

export const SEARCH_FILTERS: SearchFilter[] = [
  { name: "Year", field: "budgetYear", disabled: false },
  { name: "Agency", field: "serviceAgency", disabled: false },
  { name: "Strategic Objectives", field: "objective", disabled: true },
  { name: "Appropriation / Budget Activity", field: "budget", disabled: true },
  { name: "Program", field: "program", disabled: false },
  { name: "Program Element", field: "programElement", disabled: false },
  { name: "Project", field: "projectName", disabled: false },
  { name: "Tags", field: "tags", disabled: true },
];
