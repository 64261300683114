import { RequiredKey } from "types";
import { isDataOfType } from ".";
import { components } from "types/OpenAPI";

export type KeyOfNoteType = keyof typeof NoteType;
export enum NoteType {
  NONE,
  PUBLIC,
  PRIVATE,
}

export const isNoteOfType = (
  note:
    | RequiredKey<components["schemas"]["NoteRequest"], "noteType">
    | RequiredKey<components["schemas"]["NoteResponse"], "noteType">
    | undefined,
  noteType: KeyOfNoteType | KeyOfNoteType[]
) => {
  if (!note?.noteType) return false;
  return isDataOfType(note.noteType, noteType, NoteType);
};
