type Data = string | boolean | number;
type Enum = { [key: string]: string | number };

export function isDataOfType(
  data: Data | null | undefined,
  ofType: string | string[],
  ENUM: Enum
) {
  if (data === null || data === undefined) return false;

  if (Array.isArray(ofType))
    return ofType.some((type) => dataEqualsEnumValue(data, ENUM[type]));

  return dataEqualsEnumValue(data, ENUM[ofType]);
}

export function dataEqualsEnumValue(data: Data, enumValue: Enum["value"]) {
  if (isStringArray(enumValue)) {
    return isItemIncludedInStringArray(data, enumValue as string);
  } else {
    return data.toString() === enumValue.toString();
  }
}

export function isStringArray(value: any) {
  if (typeof value !== "string") return false;
  return (value as string).includes(",");
}

export function isItemIncludedInStringArray(data: Data, arrayAsString: string) {
  const arrayOfStrings = stringToArray(arrayAsString);
  return arrayOfStrings.includes(data.toString());
}

export function stringToArray(stringEnum: string) {
  return stringEnum.split(",");
}
