import { components } from "types/OpenAPI";
import { AsyncState } from "components/SearchBox/SearchBox";

// * Context Types

type CustomOptionType = { customOption?: boolean; id?: string; title?: string };
export type SelectedOptionType =
  | (components["schemas"]["SearchResult"] & CustomOptionType)[]
  | string;

export const SearchType: {
  [key in components["schemas"]["SearchType"]]: string;
} = {
  0: "Unknown",
  1: "Programs",
  2: "Projects",
  3: "Subprojects",
};

type NameType =
  | "Year"
  | "Agency"
  | "Strategic Objectives"
  | "Appropriation / Budget Activity"
  | "Program"
  | "Program Element"
  | "Project"
  | "Tags";
export type FieldType =
  | "budgetYear"
  | "serviceAgency"
  | "objective"
  | "budget"
  | "program"
  | "programElement"
  | "projectName"
  | "tags";
export interface SearchFilter {
  name: NameType;
  field: FieldType;
  disabled: boolean;
}

export type Filters = Partial<Record<FieldType, string[]>>;

export interface TransformedFilters {
  all?: Filters;
  programs?: Filters;
  projects?: Filters;
  subprojects?: Filters;
}

export interface Options {
  all?: Filters;
  programs?: Filters;
  projects?: Filters;
  subprojects?: Filters;
}

export type TransformedResultsType = components["schemas"]["SearchResult"] & {
  readonly matchedStringsHTML?: (string | JSX.Element | JSX.Element[])[];
};
export type TransformedKeywordResultsType = Omit<
  components["schemas"]["KeywordResults"],
  "resultsList"
> & {
  resultsList?: TransformedResultsType[];
};

export interface TransformedKeywordResults {
  all: TransformedKeywordResultsType;
  programs?: TransformedKeywordResultsType;
  projects?: TransformedKeywordResultsType;
  subprojects?: TransformedKeywordResultsType;
}

export type TransformedKeywordResultsKey = keyof TransformedKeywordResults;

export interface IAsyncState extends AsyncState {
  options: TransformedResultsType[];
}

// * Reducer Types

type RequestDataAction = {
  type: "REQUEST_DATA";
  payload: string;
};
type UpdateResultsAction = {
  type: "UPDATE_RESULTS";
  payload: {
    fetchedResults: TransformedKeywordResults;
    newAllOptions: TransformedFilters;
  };
};
type UpdateSearchTypeAction = {
  type: "UPDATE_SEARCH_TYPE";
  payload: TransformedKeywordResultsKey;
};
type UpdateFiltersAction = {
  type: "UPDATE_FILTERS";
  payload: {
    updatedFilters: Filters;
    updatedDisplayedResults: TransformedKeywordResultsType;
    newlyCheckedOptions: Filters;
  };
};
type ClearAllFiltersAction = {
  type: "CLEAR_ALL_FILTERS";
};
type ClearSearchTermAction = {
  type: "CLEAR_SEARCH_TERM";
};
type ResetStateAction = {
  type: "RESET_STATE";
};

export type SearchAction =
  | RequestDataAction
  | UpdateResultsAction
  | UpdateSearchTypeAction
  | UpdateFiltersAction
  | ClearAllFiltersAction
  | ClearSearchTermAction
  | ResetStateAction;

export interface SearchState {
  activeSearchType: TransformedKeywordResultsKey;
  searchTerm: string;
  searchResults: TransformedKeywordResults;
  displayedResults: TransformedKeywordResults;
  asyncSearch: IAsyncState;
  filters: TransformedFilters;
  allOptions: Options;
  checkedOptions: Options;
}
