import { Dropdown, NavItem } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

import { useUser, logout } from "hooks/useUser";
import Avatar from "../Avatar/Avatar";
import { isUserOfRole } from "utils/enumValidators";
import { useTokenAPI } from "../../API/TokenAPI";

export default function UserProfileDropdown() {
  const [{ firstName, lastName, userRoles }, dispatch] = useUser();
  const history = useHistory();

  const { revokeToken } = useTokenAPI();

  return (
    <Dropdown as={NavItem} align="end">
      <Avatar fullName={firstName + " " + lastName} />
      <Dropdown.Menu
        className="mt-2 border-0"
        style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}
      >
        <Dropdown.Item as={NavLink} to="/my-tags">
          My Tags
        </Dropdown.Item>
        <Dropdown.Item as={NavLink} to="/my-bookmarks">
          My Bookmarks
        </Dropdown.Item>
        <Dropdown.Item as={NavLink} to="/my-exports">
          My Exports
        </Dropdown.Item>
        {isUserOfRole({ userRoles }, "POWER") && (
          <Dropdown.Item as={NavLink} to="/user-management">
            User Management
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => {
            revokeToken();
            dispatch(logout());
            history.push("/login", { viewType: "LOGOUT" });
          }}
          active={false}
        >
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
