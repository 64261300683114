import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useGetLatest } from "react-table";
import { isUserOfRole } from "../../utils/enumValidators";
import userReducer, {
  getInitialUser,
  UserAction,
  UserState,
} from "./userReducer";

export interface UserDecorators {
  readonly basic: boolean;
  readonly power: boolean;
}
export interface IsUser {
  (): UserDecorators;
}
export interface DecoratedUserState extends UserState {
  readonly isUser: IsUser;
}
export const UserContext = createContext<
  [DecoratedUserState, React.Dispatch<UserAction>]
>([{} as DecoratedUserState, () => void 0]);

export const useUser = () => useContext(UserContext);

function generateIsUser(state: UserState) {
  return {
    basic: isUserOfRole(state, ["BASIC"]),
    power: isUserOfRole(state, ["POWER"]),
  };
}

interface IProps {
  readonly initialState?: Partial<UserState>;
  readonly children: React.ReactNode;
}
export default function UserContextProvider({
  initialState,
  children,
}: IProps) {
  const [state, dispatch] = useReducer(
    userReducer,
    getInitialUser(initialState)
  );

  const stateDecorator = useRef<UserDecorators>(generateIsUser(state));
  // * useLayoutEffect guarantees stateDecorator will begin transforming
  // * state by the frame after first render.
  useLayoutEffect(() => {
    stateDecorator.current = generateIsUser(state);
  }, [state]);
  const isUser = useGetLatest(stateDecorator.current ?? {});
  Object.assign(state, { isUser });

  const value = useMemo(
    () => [state as DecoratedUserState, dispatch],
    [state]
  ) as [DecoratedUserState, React.Dispatch<UserAction>];

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
