import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import { useRef, useCallback, Dispatch } from "react";

export interface Fairing extends AxiosInstance {
  fairings: FairingPlugin[];
  state: ApiState;
  dispatch: Dispatch<any>;
  hooks?: {
    responseInterceptors: Array<ResponseInterceptor | undefined>;
    responseErrorInterceptors: Array<ErrorInterceptor | undefined>;
    requestInterceptors: Array<RequestInterceptor | undefined>;
    requestErrorInterceptors: Array<ErrorInterceptor | undefined>;
    stateReducers: Array<StateReducer>;
    useInstance: Array<(instance: Fairing) => void>;
  } & Record<string, any>;
  getHooks(): Fairing["hooks"];
}

// TODO Define Plugin pass-down system to properly type apiState
export type ApiState = Record<string, any>;

// Intercepting common errors + allowing custom messages to be set
// Default used if not set
export type CustomErrorMessages = {
  [key: string]: {
    [key: string]: string;
  };
};

export interface StateReducer {
  (
    newState: ApiState,
    action: { type: string } & Record<string, any>,
    prevState?: Record<string, any>,
    instance?: Fairing
  ): ApiState | undefined;
}
export interface ResponseInterceptor {
  (value: AxiosResponse<any>, instance: Fairing): AxiosResponse<any>;
}
export interface ErrorInterceptor {
  (error: any, instance: Fairing): any;
}
export interface RequestInterceptor {
  (value: AxiosRequestConfig, instance: Fairing): AxiosRequestConfig;
}

export interface FairingPlugin {
  (fairings: Fairing["hooks"]): void;
  pluginName: string;
}

export const actions = {
  init: "init",
};

export function loopHooks(hooks: Array<any>, context: any): void {
  hooks.forEach((hook) => {
    hook(context);
  });
}

export function reduceHooks<T>(
  hooks: Array<((initial: T, fairing: Fairing) => T) | undefined>,
  initial: T,
  fairing: Fairing
) {
  return hooks.reduce((prev, next) => {
    if (next === undefined) return prev;
    return next(prev, fairing);
  }, initial);
}

export function useGetLatest<T>(obj: T) {
  const ref = useRef<T>();
  ref.current = obj;

  return useCallback(() => ref.current as T, []);
}
