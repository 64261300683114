import { isDataOfType } from ".";
import { UserState } from "hooks/useUser";
import { RequiredKey } from "types";

export type KeyOfUserRole = keyof typeof UserRole;
export enum UserRole {
  UNKNOWN = 0,
  BASIC,
  POWER,
}

export const isUserOfRole = (
  user: RequiredKey<UserState, "userRoles"> | undefined,
  userRole: KeyOfUserRole | KeyOfUserRole[]
) => {
  if (!user) return false;
  const role = user?.userRoles;
  return isDataOfType(role, userRole, UserRole);
};

export type KeyOfUserStatus = keyof typeof UserStatus;
export enum UserStatus {
  UNKNOWN = 0,
  ACTIVE,
  LOCKED,
  DENIED,
  PENDING,
}

export const isUserOfStatus = (
  user: RequiredKey<UserState, "status"> | undefined,
  userStatus: KeyOfUserStatus | KeyOfUserStatus[]
) => {
  if (!user) return false;
  const status = Number(user?.status);
  return isDataOfType(status, userStatus, UserStatus);
};
