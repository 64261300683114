import React, { useReducer, useEffect, useMemo } from "react";

import { useUser } from "hooks/useUser";
import useWebSockets, {
  ReceiveTagProgressType,
  ReceiveExportProgressType,
} from "context/NotificationsContext/useWebSockets";
import { uniqBy } from "lodash";

const NotificationsContext = React.createContext<
  [ReceiveTagProgressType[], React.Dispatch<NotificationAction>]
>([[], () => void 0]);

const ExportNotificationsContext = React.createContext<
  [ReceiveExportProgressType[], React.Dispatch<ExportNotificationAction>]
>([[], () => void 0]);

interface IProps {
  readonly children: React.ReactNode;
}

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
interface IAddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: ReceiveTagProgressType;
}

interface IAddExportNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: ReceiveExportProgressType;
}

export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
interface IRemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: string;
}

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
interface IClearNotificationAction {
  type: typeof CLEAR_NOTIFICATIONS;
}

export type NotificationAction =
  | IAddNotificationAction
  | IRemoveNotificationAction
  | IClearNotificationAction;

export type ExportNotificationAction =
  | IAddExportNotificationAction
  | IRemoveNotificationAction
  | IClearNotificationAction;

// * this component could be expanded to accomodate action links if determined it is needed (example would be the keep alive toast)

const toastReducer: React.Reducer<
  ReceiveTagProgressType[],
  NotificationAction
> = (prevState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return uniqBy([action.payload, ...prevState], "tagValue");
    }
    case REMOVE_NOTIFICATION:
      return prevState.filter(({ tagValue }) => tagValue !== action.payload);
    case CLEAR_NOTIFICATIONS:
      return [];
  }
};

const toastExportReducer: React.Reducer<
  ReceiveExportProgressType[],
  ExportNotificationAction
> = (prevState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      console.log(action.payload);
      return uniqBy([action.payload, ...prevState], "exportFileName");
    }
    case REMOVE_NOTIFICATION:
      return prevState.filter(
        ({ exportFileName }) => exportFileName !== action.payload
      );
    case CLEAR_NOTIFICATIONS:
      return [];
  }
};

function NotificationsContextProvider({ children }: IProps) {
  const [notifications, dispatch] = useReducer(toastReducer, []);
  const [, exportDispatch] = useReducer(toastExportReducer, []);
  const [{ isLoggedIn }] = useUser();

  useEffect(() => {
    if (!isLoggedIn) dispatch({ type: CLEAR_NOTIFICATIONS });
  }, [isLoggedIn]);

  useWebSockets({ dispatch, exportDispatch });

  const value = useMemo(() => [notifications, dispatch], [notifications]) as [
    ReceiveTagProgressType[],
    React.Dispatch<NotificationAction>
  ];

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

function ExportNotificationsContextProvider({ children }: IProps) {
  const [notifications, exportDispatch] = useReducer(toastExportReducer, []);
  const [, dispatch] = useReducer(toastReducer, []);
  const [{ isLoggedIn }] = useUser();

  useEffect(() => {
    if (!isLoggedIn) exportDispatch({ type: CLEAR_NOTIFICATIONS });
  }, [isLoggedIn]);

  useWebSockets({ dispatch, exportDispatch });

  const value = useMemo(
    () => [notifications, exportDispatch],
    [notifications]
  ) as [ReceiveExportProgressType[], React.Dispatch<ExportNotificationAction>];

  return (
    <ExportNotificationsContext.Provider value={value}>
      {children}
    </ExportNotificationsContext.Provider>
  );
}

export {
  NotificationsContext,
  NotificationsContextProvider,
  ExportNotificationsContext,
  ExportNotificationsContextProvider,
};
