import { useEffect } from "react";
import * as signalR from "@microsoft/signalr";

import { useUser } from "hooks/useUser";
import {
  ExportNotificationAction,
  NotificationAction,
} from "context/NotificationsContext";

export type TagActionType = "CREATE" | "DELETE" | "MERGE";
export type ExportActionType = "CREATE" | "DELETE";
export type ReceiveTagProgressType = {
  action: TagActionType;
  tagValue: string;
  strategicObjective: string;
  percentageComplete: number;
};

export type ReceiveExportProgressType = {
  action: ExportActionType;
  exportFileName: string;
  percentageComplete: number;
};
export const convertReceiveMessageData = (
  action: TagActionType,
  args: string
): ReceiveTagProgressType => {
  const tagProgress: ReceiveTagProgressType = JSON.parse(args);
  return {
    action,
    tagValue: decodeURIComponent(tagProgress.tagValue),
    strategicObjective: decodeURIComponent(tagProgress.strategicObjective),
    percentageComplete: tagProgress.percentageComplete * 100,
  };
};

export const convertReceiveExportMessageData = (
  action: ExportActionType,
  args: string
): ReceiveExportProgressType => {
  const exportProgress: ReceiveExportProgressType = JSON.parse(args);
  return {
    action,
    exportFileName: decodeURIComponent(exportProgress.exportFileName),
    percentageComplete: exportProgress.percentageComplete * 100,
  };
};

export type OnConnectionObject<D = string> = {
  message: string;
  call(args: D): void;
};

const WS_URL = "/api/notificationHub";

const useWebSockets = ({
  dispatch,
  exportDispatch,
}: {
  dispatch: React.Dispatch<NotificationAction>;
  exportDispatch: React.Dispatch<ExportNotificationAction>;
}) => {
  const [{ id }] = useUser();

  // endpoints
  const onReceiveTagCreateProgress: OnConnectionObject<string> = {
    message: "ReceiveTagCreateProgress",
    call: (args) => {
      const notification = convertReceiveMessageData("CREATE", args);
      dispatch({ type: "ADD_NOTIFICATION", payload: notification });
    },
  };
  const onReceiveTagMergeProgress: OnConnectionObject<string> = {
    message: "ReceiveTagMergeProgress",
    call: (args) => {
      const notification = convertReceiveMessageData("MERGE", args);
      dispatch({ type: "ADD_NOTIFICATION", payload: notification });
    },
  };
  const onReceiveTagDeleteProgress: OnConnectionObject<string> = {
    message: "ReceiveTagDeleteProgress",
    call: (args) => {
      const notification = convertReceiveMessageData("DELETE", args);
      dispatch({ type: "ADD_NOTIFICATION", payload: notification });
    },
  };

  const onReceiveExportCreateProgress: OnConnectionObject<string> = {
    message: "ReceiveExportCreateProgress",
    call: (args) => {
      const notification = convertReceiveExportMessageData("CREATE", args);
      exportDispatch({ type: "ADD_NOTIFICATION", payload: notification });
      console.log("got the notification");
    },
  };

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(WS_URL, {
        transport: signalR.HttpTransportType.LongPolling,
      })
      .configureLogging(signalR.LogLevel.None)
      .build();
    if (id) {
      connection
        .start()
        .then(() => {
          // TODO: Fetch user notifications
        })
        .catch((err) => console.log("Notifications Connection Error: ", err));

      for (const callback of [
        onReceiveTagCreateProgress,
        onReceiveTagMergeProgress,
        onReceiveTagDeleteProgress,
        onReceiveExportCreateProgress,
      ]) {
        connection.on(callback.message, (args) => {
          callback.call(args as string);
        });
      }
    }
    return () => {
      connection.stop();
    };
    // eslint-disable-next-line
  }, [id]);
};

export default useWebSockets;
