import { useCallback } from "react";

import {
  FairingPlugin,
  ResponseInterceptor,
  ErrorInterceptor,
} from "API/APILib";
import { useUser } from "../hooks/useUser";
import { logout, keepAlive } from "../hooks/useUser/userReducer";

export const useKeepAliveFairing: FairingPlugin = (fairings) => {
  // ? Connect to UserContext
  const [{ email, id: userId }, dispatch] = useUser();

  const getUser = useCallback(() => {
    if (Boolean(userId) && Boolean(email)) {
      return { userId, email };
    }
    return null;
  }, [userId, email]);

  if (fairings) {
    // ? consume logout dispatch and add it to fairing
    fairings.logout = () => dispatch(logout());
    // ? consume resetKeepAlive dispatch and add it to fairing
    fairings.resetKeepAlive = () => dispatch(keepAlive());
    // ? create user context getter and attach to apiInstance
    fairings.getUser = getUser;

    // ? push useAPI lifecycle functions
    fairings.responseInterceptors.push(responseInterceptor);
    fairings.responseErrorInterceptors.push(responseErrorInterceptor);
  }
};
useKeepAliveFairing.pluginName = "useKeepAliveFairing";

const responseInterceptor: ResponseInterceptor = (response, fairing) => {
  // ? Only Reset user context Keep Alive if we have user info
  if (fairing?.getHooks()?.getUser() !== null) {
    fairing.getHooks().resetKeepAlive();
  }
  return response;
};
// ? logout if response status is 401
const responseErrorInterceptor: ErrorInterceptor = (error, fairing) => {
  if (error.response && error.response.status === 401) {
    fairing.getHooks()?.logout();
  }
  return error;
};
