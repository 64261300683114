import { ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import "./Loading.scss";

export type LoadingState = "loading" | "failed" | "finished";

interface LoadingProps {
  readonly error?: boolean;
}

interface WithLoadingProps {
  readonly loading: LoadingState | boolean;
  readonly children: ReactNode[] | ReactNode;
  error?: boolean;
}

const errorBlock = (
  <div className="loading-block">
    <output className="failed">
      <span className="sr-only" aria-hidden="true">
        Failed
      </span>
    </output>
  </div>
);

const loadingSpinner = (
  <div className="loading-block">
    <Spinner className="loading" aria-label="Loading">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export function Loading({ error }: LoadingProps) {
  return error ? errorBlock : loadingSpinner;
}

// * this prevents us from having to write a ternary whenever we want to render <Loading> conditionally
export function WithLoading({
  loading,
  error,
  children,
}: Readonly<WithLoadingProps>) {
  if (error || loading === "failed") {
    return <>{error}</>;
  } else if (!loading || loading === "finished") {
    return <>{children}</>;
  } else {
    return <>{loadingSpinner}</>;
  }
}
