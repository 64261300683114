import {
  SearchAction,
  SearchState,
  REQUEST_DATA,
  UPDATE_FILTERS,
  UPDATE_RESULTS,
  UPDATE_SEARCH_TYPE,
  CLEAR_ALL_FILTERS,
  CLEAR_SEARCH_TERM,
  RESET_STATE,
  initialState,
} from ".";

export const searchReducer = (
  state: SearchState,
  action: SearchAction
): SearchState => {
  const {
    asyncSearch,
    displayedResults,
    searchResults,
    activeSearchType,
    filters,
    checkedOptions,
  } = state;

  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        asyncSearch: { ...asyncSearch, isLoading: true },
        searchTerm: action.payload,
      };

    case UPDATE_RESULTS:
      const { fetchedResults, newAllOptions } = action.payload;

      return {
        ...state,
        asyncSearch: {
          ...asyncSearch,
          isLoading: false,
          options: fetchedResults.all.resultsList.slice(0, 10),
        },
        searchResults: fetchedResults,
        displayedResults: fetchedResults,
        allOptions: newAllOptions,
        activeSearchType: "all",
      };

    case UPDATE_SEARCH_TYPE:
      return {
        ...state,
        activeSearchType: action.payload,
      };

    case UPDATE_FILTERS:
      const { updatedFilters, updatedDisplayedResults, newlyCheckedOptions } =
        action.payload;

      return {
        ...state,
        displayedResults: {
          ...displayedResults,
          [activeSearchType]: updatedDisplayedResults,
        },
        filters: { ...filters, [activeSearchType]: updatedFilters },
        checkedOptions: {
          ...checkedOptions,
          [activeSearchType]: {
            ...checkedOptions[activeSearchType],
            ...newlyCheckedOptions,
          },
        },
      };

    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filters: { ...filters, [activeSearchType]: {} },
        checkedOptions: {
          ...checkedOptions,
          [activeSearchType]: {},
        },
        displayedResults: {
          ...displayedResults,
          [activeSearchType]: searchResults[activeSearchType],
        },
      };

    case CLEAR_SEARCH_TERM:
      return {
        ...state,
        searchTerm: "",
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
