export enum ExportType {
  None = 0,
  Strategic = 1,
  Search = 2,
  Bookmark = 3,
  Cost = 4,
}

export enum ExportStatus {
  None = 0,
  Completed = 1,
  Failed = 2,
  "In Progress" = 3,
}
