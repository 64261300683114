import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { camelCase } from "lodash";
import axios, { CancelTokenSource } from "axios";

import { useSearchAPI } from "API/SearchAPI";
import { components } from "types/OpenAPI";
import {
  SelectedOptionType,
  TransformedResultsType,
  Filters,
  TransformedKeywordResultsKey,
  FieldType,
  SearchContext,
  updateAllOptions,
  addHTMLToResults,
  getSearchTypeResults,
} from ".";
import { RecordType } from "utils/enumValidators/record";

export const useSearch = () => {
  const history = useHistory();
  const { getProjectsByKeyword } = useSearchAPI();
  const [
    { searchResults, activeSearchType, searchTerm, filters, ...rest },
    dispatch,
  ] = useContext(SearchContext);

  const [activeFilter, setActiveFilter] = useState<{
    all?: FieldType;
    programs?: FieldType;
    projects?: FieldType;
    subprojects?: FieldType;
  }>({});

  const updateSearchResults = (
    results: components["schemas"]["KeywordResults"]
  ) => {
    const { resultsList, totalResults } = results;
    const allResults = addHTMLToResults(resultsList);
    const programs = getSearchTypeResults(allResults, "Programs");
    const projects = getSearchTypeResults(allResults, "Projects");
    const subprojects = getSearchTypeResults(allResults, "Subprojects");

    const fetchedResults = {
      all: { resultsList: allResults, totalResults },
      programs,
      projects,
      subprojects,
    };
    const newAllOptions = {
      all: updateAllOptions(allResults),
      programs: updateAllOptions(programs.resultsList),
      projects: updateAllOptions(projects.resultsList),
      subprojects: updateAllOptions(subprojects.resultsList),
    };

    dispatch({
      type: "UPDATE_RESULTS",
      payload: {
        fetchedResults,
        newAllOptions,
      },
    });
  };

  const requestSourceRef = useRef<CancelTokenSource>();
  const handleOnSearch = useCallback(
    (_searchTerm: string) => {
      dispatch({
        type: "REQUEST_DATA",
        payload: _searchTerm,
      });

      const source = axios.CancelToken.source();
      if (!!requestSourceRef.current) {
        requestSourceRef.current.cancel();
      }
      requestSourceRef.current = source;

      getProjectsByKeyword(
        RecordType.NONE,
        { searchTerm: _searchTerm?.replace(/[[\]]/g, "") },
        {
          cancelToken: source.token,
        }
      ).then((res) => {
        const { resultsList = [], totalResults = 0 } = res?.data || {};
        const allResults = addHTMLToResults(resultsList);
        const programs = getSearchTypeResults(allResults, "Programs");
        const projects = getSearchTypeResults(allResults, "Projects");
        const subprojects = getSearchTypeResults(allResults, "Subprojects");

        const fetchedResults = {
          all: { resultsList: allResults, totalResults },
          programs,
          projects,
          subprojects,
        };
        const newAllOptions = {
          all: updateAllOptions(allResults),
          programs: updateAllOptions(programs.resultsList),
          projects: updateAllOptions(projects.resultsList),
          subprojects: updateAllOptions(subprojects.resultsList),
        };

        dispatch({
          type: "UPDATE_RESULTS",
          payload: {
            fetchedResults,
            newAllOptions,
          },
        });
      });
    },
    [dispatch, getProjectsByKeyword]
  );

  const startNewSearch = (selectedSearchTerm: string) => {
    if (searchTerm !== selectedSearchTerm) {
      handleOnSearch(selectedSearchTerm);
    }
  };

  const handleSelection = (data: SelectedOptionType) => {
    if (typeof data === "string") {
      startNewSearch(data);
      return history.push(`/search?keyword=${encodeURIComponent(data)}`);
    }

    const [{ title, customOption, source }] = data;

    if (customOption) {
      startNewSearch(title);
      return history.push(`/search?keyword=${encodeURIComponent(title)}`);
    }

    history.push(`/record/${source.tableName}/${source.rowId}`);
  };

  const filterBySearchType = (category: string) => {
    dispatch({
      type: "UPDATE_SEARCH_TYPE",
      payload: category.toLowerCase() as TransformedKeywordResultsKey,
    });
  };

  const getCheckedOptions = useCallback(
    (filterType: FieldType) =>
      filters[activeSearchType]?.[camelCase(filterType) as FieldType] || [],
    [activeSearchType, filters]
  );

  const handleUpdateFilter = useCallback(
    (field: string, options: string[]) => {
      // ? replace current filter value
      const updatedFilters: Filters = {
        ...filters[activeSearchType],
        [camelCase(field)]: [...options],
      };
      // ? Clear out empty collections within filter
      if (options.length === 0) {
        delete updatedFilters[camelCase(field) as FieldType];
      }

      const resultSpecificKeys = Object.keys(updatedFilters) as FieldType[]; // Year, Program, etc

      if (!resultSpecificKeys.length) {
        return dispatch({
          type: "UPDATE_FILTERS",
          payload: {
            updatedFilters,
            updatedDisplayedResults: searchResults[activeSearchType],
            newlyCheckedOptions: { [field]: options },
          },
        });
      }

      let _filteredResults: TransformedResultsType[] = [];
      _filteredResults = [
        ..._filteredResults,
        ...searchResults[activeSearchType].resultsList.filter(
          (_searchResult: any) =>
            resultSpecificKeys.every((key) =>
              updatedFilters[key].includes(_searchResult[key])
            )
        ),
      ];

      return dispatch({
        type: "UPDATE_FILTERS",
        payload: {
          updatedFilters,
          updatedDisplayedResults: {
            resultsList: _filteredResults,
            totalResults: _filteredResults.length,
          },
          newlyCheckedOptions: { [field]: options },
        },
      });
    },
    [activeSearchType, dispatch, filters, searchResults]
  );

  const removeAllFilters = () =>
    dispatch({
      type: "CLEAR_ALL_FILTERS",
    });

  const updateCheckedOptions = (filterName: FieldType, options: string[]) => {
    handleUpdateFilter(filterName, options);
  };

  const resetSearchState = () =>
    dispatch({
      type: "RESET_STATE",
    });

  const noResultsFound = useMemo(
    () => !searchResults.all.resultsList?.length,
    [searchResults.all.resultsList?.length]
  );

  return {
    noResultsFound,
    handleOnSearch,
    searchTerm,
    handleSelection,
    filterBySearchType,
    filters,
    handleUpdateFilter,
    getCheckedOptions,
    activeSearchType,
    activeFilter,
    setActiveFilter,
    updateCheckedOptions,
    removeAllFilters,
    resetSearchState,
    updateSearchResults,
    dispatch,
    ...rest,
  };
};
