import { ReactNode } from "react";

import "./UnprotectedRouteContainer.scss";

interface UnprotectedRouteContainerProps {
  readonly children: ReactNode;
}

export default function UnprotectedRouteContainer({
  children,
}: UnprotectedRouteContainerProps) {
  return <div className="unprotected-route-container" children={children} />;
}
