import { useAPI } from "./API";

import { components } from "../types/OpenAPI";

export function useTokenAPI() {
  const [api, urlRoot] = useAPI();
  const prefix = "Token";

  const getToken = (body: components["schemas"]["TokenAuthRequest"]) => {
    return api.post<components["schemas"]["TokenResponse"]>(
      `${urlRoot}/${prefix}`,
      body
    );
  };

  const revokeToken = () => {
    return api.delete(
      `${urlRoot}/${prefix}`
    );
  }

  return {
    getToken,
    revokeToken
  };
}
