import { AxiosRequestConfig } from "axios";

import { useAPI } from "./API";
import { components } from "../types/OpenAPI";

export function useSearchAPI() {
  const [api, urlRoot] = useAPI();
  const prefix = "Search";

  const getProjectsByKeyword = (
    recordType: components["schemas"]["RecordType"],
    body: components["schemas"]["KeywordSearch"],
    config?: AxiosRequestConfig
  ) => {
    return api.post<components["schemas"]["KeywordResults"]>(
      `${urlRoot}/${prefix}/Keyword${"?recordType=" + recordType}`,
      body,
      config
    );
  };

  const searchByTag = (
    recordType: components["schemas"]["RecordType"],
    body: components["schemas"]["KeywordSearch"],
    config?: AxiosRequestConfig
  ) => {
    return api.post<components["schemas"]["KeywordResults"]>(
      `${urlRoot}/${prefix}/Tag${"?recordType=" + recordType}`,
      body,
      config
    );
  };

  const getAgencies = () => {
    return api.get<string[]>(`${urlRoot}/${prefix}/Agencies`);
  };

  const exportSearchResults = (
    body: components["schemas"]["KeywordSearch"],
    config?: AxiosRequestConfig
  ) => {
    return api.post(`${urlRoot}/${prefix}/download`, body, {
      ...config,
      responseType: "blob",
    });
  };

  return {
    getProjectsByKeyword,
    getAgencies,
    exportSearchResults,
    searchByTag,
  };
}
