import { useAPI } from "./API";

import { components } from "../types/OpenAPI";

export function useUsersAPI() {
  const [api, urlRoot] = useAPI();
  const prefix = "Users";

  const createUser = (body: components["schemas"]["UserRegistration"]) => {
    return api.post<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}`,
      body
    );
  };

  const updateUser = (body: components["schemas"]["UserUpdateRequest"]) => {
    return api.patch<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}`,
      body
    );
  };

  const getUserById = (userId: number) => {
    return api.get<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/${userId}`
    );
  };

  const getUsers = (userStatus?: number) => {
    return api.get<components["schemas"]["UserResponse"][]>(
      `${urlRoot}/${prefix}/${!!userStatus ? "?userStatus=" + userStatus : ""}`
    );
  };

  const approveUser = (userId: number) => {
    return api.patch<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/${userId}:approval?isApproved=${true}`
    );
  };

  const denyUser = (userId: number) => {
    return api.patch<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/${userId}:approval?isApproved=${false}`
    );
  };

  const unlockUser = (userId: number) => {
    return api.put<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/${userId}?unlock=${true}`
    );
  };

  const lockUser = (userId: number) => {
    return api.put<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/${userId}?unlock=${false}`
    );
  };

  const approvePendingPassword = (userId: number) => {
    return api.patch(`${urlRoot}/${prefix}/${userId}/approvePendingPassword`);
  };

  const setPendingPassword = (
    body: components["schemas"]["ResetPasswordRequest"]
  ) => {
    return api.patch<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/pendingPassword`,
      body
    );
  };

  const resetPasswordRequest = (email: string) => {
    return api.post(`${urlRoot}/${prefix}/${email}`);
  };

  const resetPassword = (
    body: components["schemas"]["ResetPasswordRequest"]
  ) => {
    return api.patch<components["schemas"]["UserResponse"]>(
      `${urlRoot}/${prefix}/resetPassword`,
      body
    );
  };

  const validateResetToken = (resetToken: string) => {
    return api.get<boolean>(`${urlRoot}/${prefix}/validate/${resetToken}`);
  };

  const keepAlive = () => {
    return api.get(`${urlRoot}/${prefix}/keepalive`);
  };

  return {
    createUser,
    updateUser,
    getUserById,
    getUsers,
    approveUser,
    denyUser,
    unlockUser,
    lockUser,
    setPendingPassword,
    resetPasswordRequest,
    resetPassword,
    validateResetToken,
    keepAlive,
    approvePendingPassword,
  };
}
