import { useRef } from "react";
import {
  AsyncTypeahead,
  AsyncTypeaheadProps,
  TypeaheadModel,
} from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

import Search from "assets/icons/search.svg";
import Clear from "assets/icons/close.svg";

import "./SearchBox.scss";

export interface AsyncState {
  allowNew: (() => boolean) | boolean;
  isLoading: boolean;
  multiple: boolean;
}

export interface IProps extends AsyncTypeaheadProps<any> {
  readonly userInput: string;
  readonly setUserInput: React.Dispatch<React.SetStateAction<string>>;
  readonly handleSelection: (e: any) => void;
  readonly filter?: boolean;
  readonly children?: any;
  readonly showClearButton?: boolean;
}

export default function SearchBox({
  userInput,
  setUserInput,
  handleSelection,
  filter = false,
  options,
  labelKey,
  showClearButton = false,
  onSearch,
  ...rest
}: IProps) {
  const typeaheadRef = useRef<AsyncTypeahead<TypeaheadModel>>(null);

  const handleUserInput = (value: string) => {
    setUserInput(value);
    let result = value;
    if (userInput.length <= value.length) {
      const plainString = value.replace(/[[\]]/g, "");
      const re = /AND|OR|\?+|\*/g;
      result = plainString.replace(re, "[$&]");
    }

    setUserInput(result);
  };

  return (
    <div className="search-box-container">
      <div className="search-icon-container">
        <img className="search-icon" src={Search} alt="search" />
      </div>
      <AsyncTypeahead
        ref={typeaheadRef}
        id="SearchBox"
        onChange={(selectedData: any) => {
          selectedData.length > 0 && handleSelection(selectedData);
        }}
        onSearch={onSearch}
        options={options}
        labelKey={labelKey}
        renderInput={({
          inputRef,
          referenceElementRef,
          ...inputProps
        }: any) => {
          return (
            <Form.Control
              {...inputProps}
              ref={(input: any) => {
                inputRef(input);
                referenceElementRef(input);
              }}
              className="rbt-input-main rbt-input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                inputProps.onChange(e);
                handleUserInput(e.currentTarget.value);
              }}
              value={userInput}
            />
          );
        }}
        promptText="Searching..."
        maxResults={10}
        flip={true}
        minLength={3}
        delay={1000}
        placeholder="Enter your search term"
        newSelectionPrefix="Continue with&nbsp;"
        filterBy={filter ? undefined : () => true}
        inputProps={{
          autoComplete: "new-autocomplete",
        }}
        // unfortunately asyncTypeahead does not properly type this,
        // so we are manually correcting it for now :/
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter" && event.currentTarget.value) {
            handleSelection(event.currentTarget.value);
          }
        }}
        {...(rest as any)}
      >
        {showClearButton && !rest.isLoading && (
          <div className="rbt-aux">
            <img
              src={Clear}
              alt="clear"
              className="clear-icon"
              onClick={() => {
                typeaheadRef.current.clear();
                onSearch("");
                setUserInput("");
              }}
            />
          </div>
        )}
      </AsyncTypeahead>
    </div>
  );
}
