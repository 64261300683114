import { Navbar } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";

import EseatLogo from "assets/images/eseat-nav-logo.png";
import UserProfileDropdown from "./UserProfileDropdown";
import KeepAliveNotification from "components/KeepAliveNotification/KeepAliveNotification";
import NavigationKeywordSearch from "./NavigationKeywordSearch";

import "./Navigation.scss";

export default function Navigation() {
  const match = useRouteMatch({
    path: "/search",
  });

  return (
    <div className="app-nav">
      <Navbar collapseOnSelect expand="lg" className="main-nav">
        <Navbar.Brand as={Link} to="/">
          <img src={EseatLogo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse
          className={
            !!match ? "justify-content-between" : "justify-content-end"
          }
        >
          {!!match && <NavigationKeywordSearch />}
          <UserProfileDropdown />
        </Navbar.Collapse>
      </Navbar>
      <KeepAliveNotification />
    </div>
  );
}
