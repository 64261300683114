import { ComponentType } from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

import { useUser } from "hooks/useUser";
import { isUserOfRole, KeyOfUserRole, UserRole } from "utils/enumValidators";
import Navigation from "components/Navigation/Navigation";

export interface IProps extends RouteProps {
  readonly component: ComponentType<any>;
  readonly authorizedUsers?: KeyOfUserRole[];
}

export default function ProtectedRoute({
  component,
  authorizedUsers,
  ...rest
}: IProps) {
  const [{ userRoles, isLoggedIn }] = useUser();
  const Component = component;
  const currentPath = useLocation().pathname;

  const setAuthorized = (userRoles: UserRole) => {
    if (isUserOfRole({ userRoles }, authorizedUsers)) authorized = true;
  };

  let authorized = authorizedUsers ? false : true;
  if (!authorized) {
    switch (userRoles) {
      case UserRole.BASIC:
        setAuthorized(UserRole.BASIC);
        break;
      case UserRole.POWER:
        setAuthorized(UserRole.POWER);
        break;
      default:
        break;
    }
  }

  const render = (props: any) => {
    if (isLoggedIn && authorized) {
      return (
        <>
          <Navigation />
          <div className="content">
            <Component {...props} />
          </div>
        </>
      );
    } else if (isLoggedIn && !authorized) {
      return <Redirect to={{ pathname: "/login" }} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: currentPath },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={(props) => render(props)} />;
}
