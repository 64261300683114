import { useEffect, useRef } from "react";

// * This code is from Dan Abramov's Blog: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// * Adapted for Typescript: On Initial Inspection I was inclined to save callback state on a useGetLatest
// * instance but realized that would require the resetInterval to track that instance too. :(
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  // ? Remember the latest callback.
  useEffect(
    function consumeCallbackAsRef() {
      savedCallback.current = callback;
    },
    [callback]
  );

  // ? Set up the interval.
  useEffect(
    function resetInterval() {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    },
    [delay]
  );
}
