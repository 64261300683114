import { isDataOfType } from ".";
import { RequiredKey } from "types";
import { components } from "types/OpenAPI";

export type KeyOfTagType = keyof typeof TagType;
export enum TagType {
  NONE,
  SYSTEM,
  USER,
}

export const isTagOfType = (
  tag: RequiredKey<components["schemas"]["TagDetail"], "tagType"> | undefined,
  tagType: KeyOfTagType | KeyOfTagType[]
) => {
  if (!tag) return false;
  const _tagType = tag?.tagType;
  return isDataOfType(_tagType, tagType, TagType);
};

export type KeyOfTermSort = keyof typeof TermSort;
export enum TermSort {
  DEFAULT = 0,
  NAME = 1,
  COUNT = 2,
  DATE = 3,
}

export const isSortByTerm = (
  termSort: 0 | 2 | 1 | 3 | undefined,
  keyOfTermSort: KeyOfTermSort | KeyOfTermSort[]
) => {
  if (termSort === undefined) return false;
  return isDataOfType(termSort, keyOfTermSort, TermSort);
};

export type KeyOfSortBy = keyof typeof SortBy;
export enum SortBy {
  UNKNOWN = 0,
  ASCENDING = 1,
  DESCENDING = 2,
}

export const isSortBy = (
  sortBy: 0 | 2 | 1 | undefined,
  keyOfSortBy: KeyOfSortBy | KeyOfSortBy[]
) => {
  if (sortBy === undefined) return false;
  return isDataOfType(sortBy, keyOfSortBy, SortBy);
};
