import parse from "html-react-parser";

import { components } from "types/OpenAPI";
import { SearchType } from ".";

export const addHTMLToResults = (
  resultsList: components["schemas"]["SearchResult"][]
) =>
  resultsList.map((result) => ({
    ...result,
    matchedStringsHTML: result.matchedStrings?.map((matchedString) =>
      parse(matchedString)
    ),
  }));

export const getSearchTypeResults = (
  allResults: components["schemas"]["SearchResult"][],
  searchType: string
) => {
  const results = allResults.filter(
    (result) => SearchType[result.searchType] === searchType
  );

  return {
    resultsList: results,
    totalResults: results.length,
  };
};
