import { orderBy, uniqBy } from "lodash";
import { TransformedResultsType, Filters, SEARCH_FILTERS } from ".";

export const getFilterOptions = (
  filterName: keyof TransformedResultsType,
  results: TransformedResultsType[]
) =>
  orderBy(
    uniqBy(results, filterName).map((result) => result[filterName])
  ) as string[];

export const updateAllOptions = (modifiedResults: TransformedResultsType[]) => {
  let newAllOptions: Filters = {};

  SEARCH_FILTERS.forEach(({ field }) => {
    const _field = field as keyof TransformedResultsType;
    const fieldOptions = {
      [_field]: getFilterOptions(_field, modifiedResults),
    };

    newAllOptions = {
      ...newAllOptions,
      ...fieldOptions,
    };
  });

  return newAllOptions;
};
