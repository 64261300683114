import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "lodash";

import SearchBox from "components/SearchBox/SearchBox";
import { components } from "types/OpenAPI";

export default function NavigationKeywordSearch() {
  const history = useHistory();
  const { search: searchQuery } = useLocation();
  const keyword = new URLSearchParams(searchQuery).get("keyword");

  const [userInput, setUserInput] = useState<string>();

  useEffect(() => {
    if (!!keyword) setUserInput(keyword);
  }, [keyword]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm = "") => {
        history.push(`/search?keyword=${encodeURIComponent(searchTerm)}`);
      }, 300),
    // don't need to watch for getTags
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (userInput !== undefined) debouncedSearch(userInput);

    return () => {
      debouncedSearch.cancel();
    };
  }, [userInput, debouncedSearch]);

  const handleSelection = (data: components["schemas"]["TagDetail"][]) => {
    if (typeof data === "string") return;
  };

  return (
    <SearchBox
      allowNew={false}
      isLoading={false}
      multiple={false}
      options={[]}
      flip={false}
      userInput={userInput}
      setUserInput={setUserInput}
      labelKey=""
      placeholder={"Search"}
      onSearch={setUserInput}
      handleSelection={handleSelection}
      minLength={1}
      useCache={false}
      showClearButton={!!userInput}
      open={false}
    />
  );
}
