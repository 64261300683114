import cookies from "react-cookies";
import { components } from "types/OpenAPI";

type TokenResponseType = components["schemas"]["TokenResponse"];
type UserResponseType = components["schemas"]["UserResponse"];

export interface UserState extends UserResponseType {
  isLoggedIn: boolean;
  loginLoading: boolean;
  requestReset: boolean;
  resetSuccess: boolean;
  resetFailure: boolean;
  lastKeepAliveReset: number;
  id?: number;
  userRoles?: number;
  status?: string;
}
const initialUser: Partial<UserState> = {
  loginLoading: false,
  requestReset: false,
  resetSuccess: false,
  resetFailure: false,
  lastKeepAliveReset: Date.now(),
};

const USER_COOKIE_KEY = "eseat-user";
export function getInitialUser(user: Partial<UserState> = {}): UserState {
  const userCookie = cookies.load(USER_COOKIE_KEY);
  return Object.assign({}, initialUser, user, userCookie, {
    isLoggedIn: Boolean(userCookie),
  });
}

enum UserActions {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  KEEP_ALIVE = "KEEP_ALIVE",
}

interface LoginAction {
  type: UserActions.LOGIN;
  user: TokenResponseType & UserResponseType;
}
export function login(
  userData: TokenResponseType & UserResponseType
): LoginAction {
  return {
    type: UserActions.LOGIN,
    user: userData,
  };
}

interface LogoutAction {
  type: UserActions.LOGOUT;
}
export function logout(): LogoutAction {
  return { type: UserActions.LOGOUT };
}

interface KeepAliveAction {
  type: UserActions.KEEP_ALIVE;
}
export function keepAlive(): KeepAliveAction {
  return { type: UserActions.KEEP_ALIVE };
}

export type UserAction = LoginAction | LogoutAction | KeepAliveAction;

export default function userReducer(
  prevState: UserState,
  action: UserAction
): UserState {
  switch (action.type) {
    case UserActions.LOGIN:
      cookies.save(USER_COOKIE_KEY, action.user, { path: "/" });
      return {
        ...prevState,
        isLoggedIn: true,
        ...action.user,
        lastKeepAliveReset: Date.now(),
      };

    case UserActions.LOGOUT:
      cookies.remove(USER_COOKIE_KEY, { path: "/" });
      return {
        loginLoading: false,
        requestReset: false,
        resetSuccess: false,
        resetFailure: false,
        lastKeepAliveReset: 0,
        isLoggedIn: false,
        id: undefined,
        firstName: undefined,
        lastName: undefined,
        userRoles: undefined,
        email: undefined,
        lastSignInDate: undefined,
        status: undefined,
      };

    case UserActions.KEEP_ALIVE:
      return { ...prevState, lastKeepAliveReset: Date.now() };

    default:
      return prevState;
  }
}
