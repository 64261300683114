import { createContext, useReducer, useMemo } from "react";

import { SearchAction, searchReducer, SearchState } from ".";

interface SearchContextProviderProps {
  readonly children: React.ReactNode;
}

export const initialState: SearchState = {
  activeSearchType: "all",
  searchTerm: "",
  searchResults: { all: {}, programs: {}, projects: {}, subprojects: {} },
  displayedResults: { all: {}, programs: {}, projects: {}, subprojects: {} },
  asyncSearch: {
    allowNew: true,
    isLoading: false,
    multiple: false,
    options: [],
  },
  filters: { all: {}, programs: {}, projects: {}, subprojects: {} },
  allOptions: { all: {}, programs: {}, projects: {}, subprojects: {} },
  checkedOptions: { all: {}, programs: {}, projects: {}, subprojects: {} },
};

export const SearchContext = createContext<
  [SearchState, React.Dispatch<SearchAction>]
>([initialState, () => void 0]);

export function SearchContextProvider({
  children,
}: SearchContextProviderProps) {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  const value = useMemo(() => [state, dispatch], [state]) as [
    SearchState,
    React.Dispatch<SearchAction>
  ];

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
