import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import { useRef, useEffect, useCallback, useReducer, useMemo } from "react";
import { useSnackbar } from "notistack";

import {
  FairingPlugin,
  Fairing,
  actions,
  loopHooks,
  reduceHooks,
  useGetLatest,
} from "API/APILib";
import { useKeepAliveFairing } from "hooks/useKeepAliveFairing";

export interface IApiUser {
  userId: number;
  email: string;
}

// TODO there are two fairings that we will need, cancelTokens and the asOfDate query params
// TODO since these will be used on a page-by-page basis it's important to think about how to
// TODO implement these features with a lens for future extensions for phase 2
// TODO One solution would be plugins as props-passable hooks. allows the intention of the api
// TODO usage to be easily inferred from the passed-in hooks — and to only activate the needed
// TODO features per-instance. React-Table v7 is so extensible for a similar philosophy.

export function useAPI(
  _fairings: Array<FairingPlugin> = []
): [AxiosInstance, string] {
  // ? Add Default Plugins
  const fairings = [useKeepAliveFairing, ..._fairings];

  // ? Create getApiInstance
  const apiInstance = useRef(
    axios.create({
      withCredentials: true,
    }) as Fairing
  );
  const getApiInstance = useGetLatest(apiInstance.current);

  // ? Assign plugin state defaults to our apiInstance
  Object.assign(getApiInstance(), {
    fairings,
    hooks: {
      requestInterceptors: [],
      requestErrorInterceptors: [],
      responseInterceptors: [],
      responseErrorInterceptors: [],
      useInstance: [],
      stateReducers: [],
    },
  });

  // ? Register All Fairing Hooks ASAP
  fairings.filter(Boolean).forEach((fairing) => {
    fairing(getApiInstance().hooks);
  });

  // ? Consume all hooks and make getHooks
  const getHooks = useGetLatest(getApiInstance().hooks);
  getApiInstance().getHooks = getHooks;
  delete getApiInstance().hooks;

  // ? Create Reducer for all plugins
  const reducer = useCallback(
    (_state, action) => {
      if (!action.type) {
        console.info({ action });
        throw new Error("Unknown api action");
      }
      return getHooks().stateReducers.reduce(
        // ? If Handler returns undefined/falsey, passed through existing acc
        (acc, handler) => handler(acc, action, _state, getApiInstance()) || acc,
        _state
      );
    },
    [getHooks, getApiInstance]
  );

  // ? Start Reducer and spread into apiInstance
  const [state, dispatch] = useReducer(reducer, undefined, () =>
    reducer({}, { type: actions.init })
  );
  Object.assign(getApiInstance(), {
    state,
    dispatch,
  });
  const { enqueueSnackbar } = useSnackbar();

  // ? Generate Interceptors
  const interceptors = useMemo(
    () => ({
      request: (request: AxiosRequestConfig) =>
        reduceHooks(getHooks().requestInterceptors, request, getApiInstance()),
      requestError: (error: AxiosError) =>
        reduceHooks(
          getHooks().requestErrorInterceptors,
          error,
          getApiInstance()
        ),
      response: (response: AxiosResponse) =>
        reduceHooks(
          getHooks().responseInterceptors,
          response,
          getApiInstance()
        ),
      responseError: (error: AxiosError) => {
        if (!axios.isCancel(error)) {
          // * Add toast on API call failure
          enqueueSnackbar(error.response?.data?.Message || error?.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
        return Promise.reject(error);
      },
    }),
    [getHooks, getApiInstance, enqueueSnackbar]
  );

  // ? Subscribe/Unsubscribe interceptors to instance.
  useEffect(() => {
    const requestInterceptor = getApiInstance().interceptors.request.use(
      interceptors.request,
      interceptors.requestError
    );
    const responseInterceptor = getApiInstance().interceptors.response.use(
      interceptors.response,
      interceptors.responseError
    );
    return () => {
      getApiInstance().interceptors.request.eject(requestInterceptor);
      getApiInstance().interceptors.response.eject(responseInterceptor);
    };
  }, [interceptors, getApiInstance]);

  loopHooks(getHooks().useInstance, getApiInstance());

  return [getApiInstance(), "/api"];
}
