import { isDataOfType } from ".";
import { components } from "types/OpenAPI";

export type KeyOfRecordType = keyof typeof RecordType;
export enum RecordType {
  NONE,
  PROGRAM,
  PROJECT,
  SUB_PROJECT,
}

export const isRecordOfType = (
  type: components["schemas"]["RecordType"] | undefined,
  recordType: KeyOfRecordType | KeyOfRecordType[]
) => {
  if (!type) return false;
  return isDataOfType(type, recordType, RecordType);
};
