import { useState, useCallback, useMemo } from "react";
import { Toast, Button } from "react-bootstrap";
import moment from "moment";

import { logout, useUser } from "hooks/useUser";
import { useInterval } from "hooks/useInterval";
import { useUsersAPI } from "API/UsersAPI";

import "./KeepAliveNotification.scss";

const getToastClass = (show: boolean, timeRemaining: number) => {
  if (!show) return " d-none";

  if (timeRemaining > 30) return "";
  if (timeRemaining > 10) return " notice";
  return " urgent";
};

export default function KeepAliveNotification() {
  const [{ lastKeepAliveReset }, dispatch] = useUser();
  const { keepAlive } = useUsersAPI();

  // ? showTime is our display, show simplifies it to an active or inactive state.
  const [showTime, setShowTime] = useState<number>(-1);
  const show = showTime !== -1;

  const logoutWithPath = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  // ? Run Interval each second, since the logout timer is always active its
  // ? preferred to leave further management to the useInterval hook alone.
  const tick = useCallback(() => {
    // ? Take our UNIX timestamp and turn it into "seconds since last successful request"
    const secondsSinceReset = moment
      .duration(moment().diff(moment(lastKeepAliveReset)))
      .asSeconds();
    // ? 15 minutes minus 1 second to prevent 401 on the edge of the timer.
    if (secondsSinceReset >= 10 * 60 - 1) return logoutWithPath();
    // ? 14 minutes minus 1 to match the above case
    // ? and another minus 1 to show "0 seconds" properly at the end
    if (secondsSinceReset >= 9 * 60 - 2)
      return setShowTime(show ? showTime - 1 : 60);
    // ? setShowTime default to inactive state otherwise
    return setShowTime(-1);
  }, [lastKeepAliveReset, logoutWithPath, setShowTime, show, showTime]);
  useInterval(tick, 1000);

  const toastProps = useMemo(
    () => ({
      className: `keepAliveToast ${getToastClass(show, showTime)}`,
      show,
    }),
    [show, showTime]
  );

  return (
    <Toast {...toastProps}>
      <div className="headerStripe"></div>
      <Toast.Header closeButton={false}>
        <span className="py-3">
          You will be automatically logged out in <b>{showTime} seconds</b>
        </span>
      </Toast.Header>
      <Toast.Body>
        {/*
          // ? this keepAlive is a full promise but it doesn't matter to process it beyond api
          // ? interceptors determining success or failure so the .then case will just be dropped
        */}
        <Button variant="link" className="pl-0 pr-2" onClick={keepAlive}>
          Stay Logged In
        </Button>
        <Button
          variant="link"
          className="px-3 text-secondary"
          onClick={logoutWithPath}
        >
          Logout
        </Button>
      </Toast.Body>
    </Toast>
  );
}
